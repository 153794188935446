:root {
  --bluedark: #101828;
  --yellow: #ffa70f;
  --white: white;
  --gray-light: #667085;
  --gray-dark: #475467;
  --yellow-light: #ffca32;
  --black: black;
  --red: #BD0606;
}

section {
  z-index: 3;
  position: relative;
}

.w-layout-blockcontainer {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.w-layout-vflex {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.w-layout-hflex {
  flex-direction: row;
  align-items: flex-start;
  display: flex;
}

.w-checkbox {
  margin-bottom: 5px;
  padding-left: 20px;
  display: block;
}

.w-checkbox:before {
  content: " ";
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox:after {
  content: " ";
  clear: both;
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox-input {
  float: left;
  margin: 4px 0 0 -20px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  border: 1px solid #ccc;
  border-radius: 2px;
  width: 12px;
  height: 12px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-color: #3898ec;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0 0 3px 1px #3898ec;
}

.column-about {
  max-width: 400px;
}

.download-icons {
  width: 300px;
}

.logo-aliado {
  height: 100px;
}

.border-3 {
  border-radius: 3px;
}

.pd-bottom-120 {
  padding-bottom: 120px;
}

@media screen and (max-width: 991px) {
  .w-layout-blockcontainer {
    max-width: 728px;
  }
}

@media screen and (max-width: 767px) {
  .w-layout-blockcontainer {
    max-width: none;
  }
}

body {
  color: var(--bluedark);
  font-family: Inter, sans-serif;
  font-size: 16px;
  line-height: 24px;
}

h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 72px;
  font-weight: 600;
  line-height: 90px;
}

h2 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 60px;
  font-weight: 600;
  line-height: 72px;
}

h3 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
}

h4 {
  margin: 0px;
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
}

a {
  color: var(--yellow);
  font-weight: 500;
  text-decoration: none;
}

.container {
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 32px;
  padding-right: 32px;
}

.container.navbar {
  justify-content: space-between;
  align-items: center;
  padding-top: 18px;
  padding-bottom: 18px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  z-index: 1000;
  max-width: none;
}

.container.footer {
  padding-top: 64px;
  padding-bottom: 10px;
}

.container.pd-bottom-96 {
  padding-bottom: 96px;
}

.container.pd-bottom-96.pd-top-32 {
  padding-top: 32px;
}

.container.pd-bottom-96.pd-top-120 {
  padding-top: 120px;
}

.container.pd-bottom-96.pd-top-96 {
  padding-top: 96px;
}

.container.pd-top-150 {
  padding-top: 150px;
}

.container.pd-top-150.pd-bottom-50 {
  padding-bottom: 50px;
}

.container.pd-top-32 {
  padding-top: 32px;
}

.container.pd-top-32.pd-bottom-32 {
  padding-bottom: 32px;
}

.container.faqs {
  grid-column-gap: 64px;
  grid-row-gap: 64px;
  border-bottom: 1px solid #eaecf0;
  padding-top: 96px;
  padding-bottom: 96px;
}

.container-navbar {
  background-color: #0000;
}

.logo-navbar {
  height: 32px;
}

.nav-link {
  color: #475467;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  font-weight: 500;
  cursor: pointer;
}

.nav-link.dropdown {
  padding-right: 25px;
}

.icon-dropdown {
  margin-right: 0;
}

.dropdown-link {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  border: 1px solid var(--yellow);
  background-color: var(--white);
  border-radius: 8px;
  flex-flow: column;
}

.dropdown-list {
  background-color: #ddd;
}

.dropdown-list.w--open {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  background-color: #0000;
  flex-flow: column;
  padding-top: 20px;
  display: flex;
}

.btn-primary {
  background-color: var(--yellow);
  color: var(--white);
  border-radius: 8px;
  padding: 10px 16px;
  font-weight: 600;
  height: auto;
  width: auto;
}

.btn-primary.big {
  border: 1px solid var(--yellow);
  padding: 16px 22px;
  font-size: 18px;
  line-height: 28px;
}

.btn-primary.full-width {
  text-align: center;
  width: 100%;
}

.btn-primary.absolute {
  position: absolute;
  inset: 32px 32px auto auto;
}

.nav-menu {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-left: 50px;
  display: flex;
}

.container-options-menu {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  justify-content: flex-start;
  align-items: center;
}

.gap-16 {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.container-footer {
  justify-content: space-between;
  gap: 50px;
}

.container-footer.after {
  border-top: 1px solid #eaecf0;
  margin-top: 64px;
  padding-top: 32px;
}

.container-opc-footer {
  justify-content: space-between;
  width: 100%;
  max-width: 870px;
}

.letter-strong {
  font-weight: 600;
}

.copyright {
  color: var(--gray-light);
}

.font-light {
  color: var(--white);
}

.font-20 {
  font-size: 20px;
  line-height: 30px;
}

.font-24px {
  font-size: 24px;
  line-height: 29.05px;
}

.font-20.font-light.weight-500 {
  font-weight: 500;
}

.font-20.font-yellow.text-center {
  text-align: center;
}

.font-20.weight-600 {
  font-weight: 600;
}

.font-20.font-ray-dark {
  color: var(--gray-dark);
}

.first-div-banner-home {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.maxw-768 {
  max-width: 768px;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.btn-dark {
  background-color: #282828;
  border-radius: 8px;
  padding: 12px 18px;
  font-weight: 600;
}

.btn-dark.btn-form {
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 8px;
  padding: 11.5px 18px;
}

.fields {
  color: var(--gray-light);
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  height: 100%;
  margin-bottom: 0;
  padding: 12px 14px;
  background-color: white;
}

.select-field {
  padding: 11px 14px;
}

.left-field {
  border-right: 0px;
  border-radius: 8px 0px 0px 8px;
  width: 30%;
}

.right-field {
  border-radius: 0px 8px 8px 0px;
}

.field-code {
  font-size: 20px;
  text-align: center;
  padding: 20px 14px;
}

.fields._w-786 {
  width: 786px;
}

.container-form-started {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.font-small {
  font-size: 14px;
  line-height: 20px;
}

.font-small.font-light.weight-600 {
  font-weight: 600;
}

.font-small._w-bg-yellow {
  color: var(--yellow);
  background-color: #fffbee;
  border: 1px solid #fff2db;
  border-radius: 16px;
  padding: 10px 12px;
}

button._w-bg-yellow {
  color: var(--yellow);
  background-color: #fffbee;
  border-radius: 50px;
  padding: 12px 24px;
  font-size: 24px;
}

.font-small.font-gray {
  color: #d4d4d4;
}

.font-small.font-graydark {
  color: #344054;
}

.font-small.format-password {
  margin-top: 5px;
}

.link-light {
  color: var(--white);
  text-decoration: underline;
}

.gap-6 {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
}

.banner-home {
  z-index: 1;
  background-color: var(--yellow);
  border-radius: 24px;
  width: 100%;
  padding: 96px 32px 192px;
  position: relative;
}

.banner-home.mis-rifas {
  padding-top: 100px;
  padding-bottom: 100px;
}

.container-gift {
  z-index: 3;
  background-color: var(--white);
  border-radius: 12px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding: 32px;
  position: relative;
  top: -118px;
  box-shadow: 0 24px 48px -12px #1018282e;
}

.title-gift {
  font-size: 58px;
  line-height: 68px;
}

.font-yellow {
  color: var(--yellow);
}

.img-valorado {
  background-color: var(--white);
  border-radius: 100%;
  padding: 16px;
  position: absolute;
  inset: auto -60px -39px auto;
}

.div-text-rifas-calientes {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-content: center;
  align-items: center;
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
}

.font-medium {
  font-size: 20px;
  line-height: 30px;
}

.font-medium.text-center {
  text-align: center;
}

.grid-rifas-calientes {
  grid-column-gap: 139px;
  grid-row-gap: 70px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  width: 100%;
  display: grid;
}

.div-rifa-caliente {
  background-image: linear-gradient(#0000, #000c), url('../public/foto-rifa-caliente.png');
  background-position: 0 0, 50%;
  background-repeat: repeat, no-repeat;
  background-size: auto, cover;
  border-radius: 20px;
  justify-content: space-between;
  height: 383px;
  padding: 20px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.price-rifa-caliente {
  color: var(--white);
  background-color: #0000003d;
  border-radius: 20px;
  margin-left: auto;
  padding: 4px 10px 4px 8px;
}

.container-rifas-calientes {
  grid-column-gap: 64px;
  grid-row-gap: 64px;
}

.bg-light-yellow {
  background-color: #fffaf1;
}

.container-opc-tabs {
  border: 1.5px solid var(--yellow);
  background-color: var(--white);
  border-radius: 16px;
  padding: 4px;
}

.opc-tab {
  border: 1.5px solid var(--white);
  color: var(--yellow);
  text-align: center;
  background-color: #0000;
  border-radius: 16px;
  padding: 2px 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.opc-tab.w--current {
  border-color: var(--yellow);
  background-color: #0000;
}

.container-tabs {
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.align-center {
  justify-content: flex-start;
  align-items: center;
}

.align-center.gap-24 {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
}

.align-center.gap-24.opc-menu {
  width: 330px;
  padding: 8px 100px 8px 30px;
}

.align-center.gap-24.opc-menu:hover {
  background-color: var(--white);
  color: var(--yellow);
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}


.align-center.gap-24.opc-menu.active {
  background-color: var(--white);
  color: var(--yellow);
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.align-center.gap-32.pd-top-80 {
  padding-top: 80px;
}

.btn-light {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  background-color: var(--white);
  color: var(--yellow);
  text-align: center;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  padding: 16px 22px;
  font-size: 18px;
  line-height: 28px;
  display: flex;
}

.btn-light.full-width {
  text-align: center;
  justify-content: center;
  width: 100%;
}

.container-buttons-center {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  justify-content: center;
  align-items: flex-start;
  margin-left: auto;
  margin-right: auto;
}

.container-winners {
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  padding-top: 96px;
  padding-bottom: 64px;
}

.bg-line {
  background-image: url('../public/Background.svg');
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: 100%;

}

.video-winners {
  z-index: 99;
  border-radius: 8px;
  width: 916px;
  margin-bottom: 96px;
  margin-left: auto;
  margin-right: auto;
}

.container-month-winners {
  grid-column-gap: 64px;
  grid-row-gap: 64px;
  border-top: 1px solid #eaecf0;
  padding-top: 96px;
  padding-bottom: 96px;
}

.div-numbers-month-winners {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  background-color: #fffaf1;
  border-radius: 16px;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 64px;
}

.font-18 {
  font-size: 18px;
  line-height: 28px;
}

.font-18.weight-600 {
  font-weight: 600;
}

.font-18.weight-600.text-center {
  text-align: center;
}

.font-18.weight-500 {
  font-weight: 500;
}

.container-puntos-fuertes {
  justify-content: space-between;
  width: 100%;
}

.div-punto-fuerte {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  text-align: center;
  justify-content: flex-start;
  align-items: center;
  max-width: 384px;
}

.btn-link {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.container-review-app {
  grid-column-gap: 64px;
  grid-row-gap: 64px;
}

.expandable-single {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  cursor: pointer;
  border-top: 1px solid #eaecf0;
  flex-direction: column;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 0;
  display: flex;
}

.expandable-single.contact-page {
  border-top-width: 0;
  border-radius: 16px;
  padding: 32px;
}

.expandable-single.contact-page .arrow-expand-master {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  transition: transform 500ms;
}



.expandable-single.contact-page.active {
  background-color: #fffbee;
}

.expandable-single.contact-page.active .arrow-expand-master {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-45deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
}

.expandable-single.first {
  border-top-width: 0;
  padding-top: 0;
}

.expandable-top {
  grid-column-gap: 16px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.expandable-top.contact-page {
  justify-content: flex-start;
}

.arrow-expand-master {
  flex: none;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  display: flex;
  position: relative;
}

.arrow-expand-master.open {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-45deg) skew(0deg, 0deg);
  transition: all 0.5s ease;
}

.arrow-expand-master.close {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transition: all 0.5s ease;
}

.expandable-bottom {
  transform-origin: 50% 0;
  flex-direction: column;
  align-items: flex-start;
  width: 90%;
  display: flex;
  overflow: hidden;
}

.expandable-bottom.open {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1);
  transition: all 0.5s ease;
  opacity: 1;
  height: auto;
}

.expandable-bottom.close {
  transform: translate3d(0px, 20px, 0px) scale3d(1, 0, 1);
  transition: all 0.5s ease;

  opacity: 0;
  height: 0;
}

.rich-text-block {
  line-height: 28.8px;
}

.rich-text-block.faq {
  border-radius: 0;
  margin-bottom: 16px;
}

.container-faqs {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  width: 768px;
  margin-left: auto;
  margin-right: auto;
}

.container-proveedores {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  /*   background-color: #f9fafb; */
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  padding: 64px;
}

.div-logos {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.container-garantizantes {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  justify-content: flex-start;
  align-items: center;
  padding-top: 64px;
  padding-bottom: 64px;
}

.bg-gray {
  background-color: #f9fafb;
}

.container-form-log {
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 120px 0px;
}

.container-full-yellow {
  background-color: var(--yellow);
  width: 50%;
}

.container-full-yellow._w-border {
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
}

.container-log {
  align-items: stretch;
}

.font-30 {
  font-size: 30px;
  line-height: 38px;
}

.container-log-fields {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-flow: column;
  display: flex;
}

.div-code-password {
  display: flex;
  flex-direction: row;
  gap: 25px;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.div-code-password.justify-center {
  justify-content: center;
}

.div-log-in {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  justify-content: flex-start;
  align-items: center;
  width: 360px;
}

.form-block-log,
.rs-picker-toggle-wrapper {
  width: 100%;
}

.img-perfil-nav {
  object-fit: cover;
  border-radius: 100%;
  width: 48px;
  height: 48px;
}

.img-perfil-nav.userpage {
  width: 96px;
  height: 96px;
}

.container-perfil-nav {
  grid-column-gap: 27px;
  grid-row-gap: 27px;
  justify-content: flex-start;
  align-items: center;
}

.menu-hamburguer {
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  display: flex;
  cursor: pointer;
}

.container-rifa-details {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  padding-top: 150px;
  padding-bottom: 64px;
}

.banner-cta-rifa {
  background-image: linear-gradient(#0000, #0006), url('../public/foto-rifa-caliente.png');
  background-position: 0 0, 50%;
  background-repeat: repeat, no-repeat;
  background-size: auto, cover;
  border-radius: 16px;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  padding: 492px 32px 32px;
  display: flex;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.banner-cta-rifa::before,
.container-hot-raffles::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, transparent, black);
  z-index: 1;
  /* Superponer el degradado sobre la imagen */
}



.banner-cta-rifa .content {
  position: relative;
  z-index: 2;
  /* Asegurar que el contenido esté sobre el degradado */
  color: white;
  /* Asegurar que el texto sea visible sobre el degradado */
}

.font-40 {
  font-size: 40px;
  line-height: 55px;
}

.font-12 {
  font-size: 12px;
  line-height: 14.52px;
}

.font-12.font-gray {
  color: #d4d4d4;
}

.font-12.font-yellowdark {
  color: #9e741e;
}

.gap-24 {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
}

.gap-24.full-width {
  width: 100%;
}

.gap-24.height-44 {
  justify-content: flex-start;
  align-items: center;
  height: 44px;
}

.gap-8 {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
}

.category {
  border: 1.5px solid var(--white);
  color: var(--white);
  border-radius: 16px;
  padding: 2px 10px;
  font-size: 14px;
  line-height: 20px;
}

.element-right {
  margin-left: auto;
}

.element-center {
  margin-left: auto;
  margin-right: auto;
}

.btn-second {
  background-color: var(--yellow-light);
  border-radius: 10px;
  padding: 12px 24px;
  font-weight: 600;
}

.btn-second.big {
  background-color: var(--yellow-light);
  border-radius: 10px;
  padding: 12px 24px;
  font-size: 24px;
  line-height: 29px;
}

.btn-second.disable {
  background-color: #cacaca;
}

.btn-disable-raffle {
  background-color: lightgray;
  cursor: not-allowed;
  opacity: 0.5;
}

.container-perfil-aliado {
  justify-content: space-between;
  align-items: center;
  padding-bottom: 64px;
}

.img-perfil-aliado {
  object-fit: cover;
  background-color: #f3f3f3;
  border-radius: 100px;
  width: 96px;
  height: 96px;
}


.font-graydark {
  color: var(--gray-dark);
}

.grid-img-aliados {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.img-aliado {
  object-fit: cover;
  border-radius: 16px;
  height: 240px;
  width: 100%;
}

.grid-select-numbers {
  grid-column-gap: 64px;
  grid-row-gap: 64px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.img-w-border {
  object-fit: cover;
  border-radius: 16px;
  width: 100%;
  height: 100%;
  position: relative;
}

.tab-opc-numbers {
  background-color: #0000;
  border: 1px solid #858585;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  font-size: 10px;
  line-height: 12px;
  display: flex;
}

.tab-opc-numbers:hover,
.tab-opc-numbers.w--current {
  border-color: var(--yellow);
  background-color: var(--yellow-light);
}

.menu-opc-numbers {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-flow: wrap;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  margin-bottom: 34px;
  display: grid;
}

.grid-numbers {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-rows: auto auto auto auto auto auto auto auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.grid-numbers.book {
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.grid-numbers.raffle {
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.container-checkbox {
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
  position: relative;
  user-select: none;
  cursor: pointer;
  position: relative;
}

.container-checkbox.no-pointer {
  cursor: unset;
}

.container-checkbox.no-hover:hover input~.check,
.container-checkbox.no-hover input:checked~.check {
  background-color: transparent;
}

.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}


.number-opc {
  z-index: 99;
  margin-bottom: 0;
  font-size: 10px;
  line-height: 12px;
  position: relative;
}

.check {
  border: 1px solid #858585;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  margin-top: 0;
  margin-left: 0;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-checkbox:hover input~.check,
.container-checkbox input:checked~.check {
  background-color: var(--yellow-light);
}


.check.w--checked {
  background-color: var(--yellow-light);
}

.container-tabs-numbers {
  grid-column-gap: 34px;
  grid-row-gap: 34px;
  flex-flow: column;
  display: flex;
}

.duv-btn-buy-numbers {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  text-align: center;
  align-items: stretch;
}

.font-32 {
  font-size: 32px;
  line-height: 38px;
}

.font-32.weight-600,
.weight-600 {
  font-weight: 600;
}

.grid-details {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-rows: auto;
  grid-template-columns: 96px auto;
  grid-auto-columns: 1fr;
  display: grid;
}

.grid-details.total {
  grid-template-rows: auto auto auto;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

.weight-800 {
  font-weight: 800;
}

.gap-10 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.gap-50 {
  grid-column-gap: 50px;
  grid-row-gap: 50px;
}

.container-metodo-pago {
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 80px 20px 20px 20px;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

@media screen and (max-width: 768px) {
  .container-metodo-pago {
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    padding-top: 80px !important;
  }
}

.container-details-pay {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-flow: column;
  display: flex;
}

.error-message {
  background-color: #FFEBEB;
  border: 1px solid #FE8989;
  border-radius: 8px;
  padding: 4px 10px;
  color: var(--red);
  font-size: 10px;
  line-height: 12px;
  width: 100%;
}

.error-big {
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  padding: 10px 20px;
}

.message {
  background-color: #fffbee;
  border: 1px solid #fff2db;
  border-radius: 8px;
  padding: 10px 10px;
  color: var(--yellow);
  font-size: 14px;
  line-height: 18px;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.message.full-width {
  grid-area: span 1 / span 3 / span 2 / span 2;
  margin-top: 0px;
}


.paragraph-small {
  font-size: 14px;
  line-height: 20px;
}

.container-paragraph {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px 0px;
}

.heading-4 {
  color: #1f140f;
  font-size: 18px;
  line-height: 28px;
}

.modal-signup {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  background-color: #fff;
  border-radius: 12px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 400px;
  padding: 24px;
  display: flex;
  position: relative;
  box-shadow: 0 20px 24px -4px #1018281a, 0 8px 8px -4px #1018280a;
}

.close-btn {
  cursor: pointer;
  background-color: #fff;
  border-radius: 100px;
  padding: 8px;
  position: absolute;
  inset: 15px 15px auto auto;
}

.close-btn.ami {
  background-color: #0000;
  border-radius: 0;
  top: 0;
  right: 0;
}

.close-btn.ami.referencia {
  top: 24px;
  right: 24px;
  padding: 0px;
}

.close-btn.notification {
  top: 0;
  right: 12px;
}

.bg-modal {
  z-index: 100000;
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
  background-color: #000c;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0%;
}

.bg-modal.bg-transparent {
  background-color: #0000;
}



.gap-32 {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
}

.gap-32.full-width {
  width: 100%;
  position: relative;
}

.gap-32.full-width.notification {
  padding-left: 20px;
  padding-right: 20px;
}

.logo-banner {
  height: 88px;
}

.container-search {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  align-items: center;
  display: flex;
}

.img-poster-profile {
  z-index: 999;
  border-radius: 12px;
  height: 460px;
  margin-top: -118px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  box-shadow: 0 24px 48px -12px #1018282e;
}

.img-poster-profile-slider {
  border-radius: 12px;
  height: 460px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  box-shadow: 0 24px 24px -12px #1018282e;
  margin-bottom: 24px;
}

.slidershadow {
  margin-top: -118px;
  z-index: 999;
}

.div-img-rifa {
  justify-content: center;
  align-items: center;
  height: 242px;
  display: flex;
}

.grid-rifas {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  width: 100%;
  display: grid;
}

.date-mis-rifas {
  color: var(--yellow);
  text-align: center;
  background-color: #fffaeb;
  border: 1px solid #fedf89;
  border-radius: 16px;
  width: 100%;
  padding: 15px 20px;
  font-size: 20px;
  font-weight: 700;
  line-height: 12px;
}

.message-rifa {
  color: #4b4b4b;
  text-align: center;
  background-color: #d0d0d0;
  border: 1px solid #686868;
  border-radius: 16px;
  width: 100%;
  padding: 15px 20px;
  font-size: 20px;
  font-weight: 700;
  line-height: 12px;
}

.message-rifa.winner,
.message.winner {
  color: #007120;
  background-color: #ebffe2;
  border-color: #007120;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-disable-error {
  color: #47546780;
}

.mx_768px {
  max-width: 768px;
}

.container-notice {
  color: white;
  border-radius: 20px;
  padding: 20px;
  gap: 11px;
}

.bg-green {
  background-color: #17B26A;
}

.bg-gray {
  background-color: #B7B7B7;
}

.bg-yellow {
  background-color: var(--yellow);
}

.container-result {
  color: white;
  max-width: 345px;
  margin: auto;
  text-align: center;
  gap: 36px;
}

.div-container-number-result {
  background-color: #885600;
  border: 2px solid #fff;
  padding: 30px 24px;
  border-radius: 10px;
  font-size: 24px;
  line-height: 29.05px;
  font-weight: 900;
}

.full-width,
.react-datepicker-wrapper {
  width: 100%;
  max-width: 100%;
}

.number-bought {
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
}

.bg-yellow {
  background-color: var(--yellow);
}

.text-riffapper {
  color: #9e741e;
  letter-spacing: 5px;
  text-transform: uppercase;
  font-size: 28px;
  font-weight: 500;
  line-height: 32.81px;
}

.img-riffaper {
  border: 5px solid var(--white);
  object-fit: cover;
  border-radius: 100%;
  width: 196px;
  height: 196px;
  margin-bottom: -98px;
}

.container-link-refeal {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  background-color: var(--yellow);
  color: var(--white);
  text-align: center;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 60px 20px;
  box-shadow: 0 2px 5px #0003;
}

.container-link-refeal.cta-riffapper {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  text-align: left;
  justify-content: center;
  align-items: flex-start;
  padding-left: 40px;
  padding-right: 40px;
}

.grid-btns-refeal {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  text-align: center;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.btn-filter {
  color: var(--gray-dark);
  background-color: #0000;
  border-top: 1px solid #d0d5dd;
  border-bottom: 1px solid #d0d5dd;
  border-right: 1px solid #d0d5dd;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 20px;
}

.btn-filter.first {
  border-left: 1px solid #d0d5dd;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.btn-filter.last {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.label-analitycs {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  border: 1px solid #000;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  padding: 2px 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.label-analitycs.green {
  color: #067647;
  background-color: #ecfdf3;
  border-color: #abefc6;
}

.container-full-width {
  width: 100%;
}

.container-full-width.gap-32.strech {
  justify-content: space-between;
}

.gap-20 {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.grid-clients-refeal {
  grid-column-gap: 60px;
  grid-row-gap: 32px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  width: 100%;
  display: grid;
}

.img-client-refeal {
  object-fit: cover;
  border: .5px solid #000;
  border-radius: 200px;
  width: 32px;
  height: 32px;
}

.dot {
  background-color: var(--yellow);
  border-radius: 100%;
  width: 8px;
  height: 8px;
}

.strech {
  justify-content: space-between;
}

.badge-orange {
  color: #ffa70f;
  background-color: #fffaeb;
  border: 1px solid #fedf89;
  border-radius: 16px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px 20px;
  font-weight: 500;
}

.container-menu-hamburguer {
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  background-color: var(--yellow);
  color: var(--white);
  justify-content: flex-start;
  align-items: flex-end;
  height: 100vh;
  padding-top: 40px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.3em;
  position: fixed;
  right: 0;
  width: 100%;
  top: 0;
  z-index: 0;
}

.line {
  background-color: #ffffff80;
  width: 260px;
  height: 1px;
  margin: 10px 62px 10px 0px;
}

.logo-menu {
  margin-right: 62px;
  height: 70px;
  margin-left: auto;
}

.icon-menu-login {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.text-menu-login {
  display: inline-block;
  vertical-align: middle;

}

.bm-item {
  color: #fff;
}

.container-qr {
  margin-left: auto;
  margin-right: auto;
}

.btn-metodo {
  margin-top: 20px;
}

.icon-star {
  height: 24px;
  width: 24px;
}


.container-checkbox-terms {
  display: block;
  position: relative;
  padding-left: 40px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  color: var(--bluedark);
  font-weight: 400;
}

.checkbox-label.link {
  color: var(--bluedark);
  font-weight: 400;
  padding-left: 0;
  text-decoration: underline;
  margin-left: 5px;
  font-size: 16px;
}

/* Hide the browser's default checkbox */
.container-checkbox-terms input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: -1px;
  left: 0;
  background-color: transparent;
  border: 2px solid #374b5a;
  border-radius: 8px;
  width: 27px;
  height: 27px;
  margin-top: 0;
}

/* On mouse-over, add a grey background color */
.container-checkbox-terms:hover input~.checkmark {
  background-color: #eee;

}

/* When the checkbox is checked, add a blue background */
.container-checkbox-terms input:checked~.checkmark {
  background-color: var(--yellow);
  border: 2px solid var(--yellow);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-checkbox-terms input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-checkbox-terms .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}


.modal-noticaciones {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  background-color: var(--white);
  border: 2px solid #dadada;
  border-radius: 8px;
  flex-flow: column;
  width: 663px;
  max-height: 857px;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  position: relative;
  overflow: auto;
  box-shadow: 0 8px 8px -4px #1018280a, 0 20px 24px -4px #1018281a;
}

.line-gray-horizontal {
  background-color: #dadada;
  width: 100%;
  height: 2px;
}

.img-notification {
  border-radius: 200px;
  width: 24px;
  height: 24px;
}

.container-notification {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  border-radius: 8px;
  margin-left: auto;
  margin-right: auto;
  padding: 24px 30px;
  box-shadow: 0 1px 4px #0000004d;
  width: 95%;
}

.container-help {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-rows: auto;
  grid-template-columns: .5fr 1fr;
  grid-auto-columns: 1fr;
  max-width: 100%;
  display: grid;
}

.container-left-bar {
  background-color: var(--yellow);
  justify-content: space-between;
  padding: 48px;
  grid-area: 1 / 1 / 2 / 2;
}

.font-24 {
  font-size: 24px;
  line-height: 32px;
}

.container-rrss {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.container-contact-form {
  justify-content: center;
  align-items: center;
  padding: 96px 20px;
}

.grid-field-contact {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.container-form-contact {
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  max-width: 480px;
}

.gap-64 {
  grid-column-gap: 64px;
  grid-row-gap: 64px;
}

.pointer {
  cursor: pointer;
}

.number-highlighted {
  background-color: yellow;
  color: black;
  font-weight: bold;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.rs-input-group.rs-input-group-inside {
  width: 100%;
  border: 1px solid #d0d5dd;
  padding: 0px;
  border-radius: 8px;
}

.rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus,
.rs-input-group:focus-within,
.rs-input-group:not(.rs-input-group-disabled):hover {
  outline: 0 !important;
  border-color: var(--yellow) !important;
}

.rs-picker-date>.rs-input-group.rs-input-group-inside .rs-input {
  padding: 12px 14px;
  color: var(--gray-light);
  font-size: 14px;
  line-height: 1.42857143;
}

.rs-input::placeholder {
  color: #999;
}


.rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
.rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content,
.rs-btn-primary {
  background-color: var(--yellow) !important;
}

.rs-calendar-month-dropdown-year-active,
.rs-calendar-month-view .rs-calendar-header-title-date {
  color: var(--yellow) !important;
}


.full-width {
  width: 100%;
}

.full-width.tabs-metodos-pago {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-flow: column;
  display: flex;
}

._w-bg-yellow {
  color: var(--yellow);
  background-color: #fffbee;
  border: 1px solid #fff2db;
  border-radius: 16px;
  padding: 10px 12px;
}

._w-bg-yellow.full-width.text-center {
  text-align: center;
}

.tab-pago {
  background-color: #0000;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
}

.tab-pago.w--current {
  border-color: var(--yellow);
  background-color: #0000;
}

.container-tab {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  display: flex;
}


.font-indications {
  line-height: 17.112px;
  font-size: 14.88px;
  font-family: -apple-system, "system-ui", "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
  color: rgb(48, 49, 61);
}


.grid-edit-profile {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.container-collapse-profile {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  background-color: #fffbee;
  border-radius: 16px;
  width: 100%;
  padding: 32px;
  position: relative;
}

.gap-64 {
  grid-column-gap: 64px;
  grid-row-gap: 64px;
}

#address-field, #image-profile {
  grid-area: span 1 / span 2 / span 1 / span 2;
}


.card-success {
  grid-column-gap: 60px;
  grid-row-gap: 60px;
  background-color: var(--white);
  border-radius: 20px;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  padding: 30px;
}

.mxw-400 {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.img-factura {
  object-fit: cover;
  border-radius: 16px;
  width: 100%;
  height: 175px;
}

.remove-button {
  position: absolute;
  padding: 0px;
  font-size: 9px;
  background: transparent;
  top: 3px;
  right: 10px;
}

.container-countdown {
  position: absolute;
  top: 20px;
  color: var(--yellow);
  border: 1px solid #FEDF89;
  background: #FFFAEB;
  border-radius: 16px;
  text-align: center;
  padding: 10px 20px;
  left: 20%;
}

.mobile {
  display: none;
}

.pd-top-120 {
  padding-top: 120px;
}

.loader {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  /* Espacio entre el loader y el botón */
}

.loader img {
  width: 25px;
  /* Ajusta el tamaño del loader */
  height: 25px;
}

.price-listado-rifa {
  color: var(--white);
  background-color: #0000008f;
  border-radius: 20px;
  padding: 4px 10px 4px 8px;
}

.z-index-10 {
  z-index: 10;
}

.nomarginBottom {
  margin-bottom: 0px;
}

.slick-dots li.slick-active button:before {
  color: var(--yellow) !important;
}



/* Estilos responsivos */
@media (max-width: 768px) {

  h2,
  h1,
  .title-gift {
    font-size: 42px;
    line-height: 1.3em;
  }

  h4 {
    font-size: 24px;
    line-height: 1.3em;
  }

  .font-20,
  .font-medium {
    font-size: 18px;
    line-height: 1.3em;
  }

  .font-18 {
    font-size: 16px;
    line-height: 1.3em;
  }

  .container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .btn-light,
  .btn-primary.big,
  .btn-dark {
    padding: 10px 22px;
  }

  .mobile {
    display: block;
  }

  .desktop {
    display: none;
  }

  .logo-navbar,
  .logo-banner {
    height: 40px;
  }

  .nav-menu {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background: #fff;
    position: absolute;
    top: 60px;
    width: 100%;
    padding: 1rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transform: translateY(-200%);
    opacity: 0;
    transition: all 0.3s ease;
    margin: 0;
    z-index: 999;
  }

  .nav-menu.menu-open {
    gap: 20px;
    right: 0;
    left: 0;
    transform: translateY(0);
    opacity: 1;
  }

  .container-options-menu {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .w-nav-button {
    display: block;
  }

  .container-footer,
  .container-opc-footer {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .items-center-mobile {
    align-items: center;
  }

  .expandable-single {
    padding-left: 24px;
  }

  .banner-home {
    padding: 60px 20px;
  }

  .container-gift {
    top: 0px;
    margin: 30px 0px;
    padding: 0px;
  }

  .container-form-started,
  .div-logos,
  .container-log,
  .container-search,
  .flex-vertical-mobile {
    flex-direction: column;
  }

  .container-form-started .w-layout-vflex,
  .container-form-started button,
  .w-form,
  .fields._w-786,
  .container-search button,
  .video-winners,
  .container-faqs,
  .div-log-in {
    width: 100%;
  }

  .img-circle-home {
    height: 90px;
  }

  .img-valorado {
    inset: auto 10px -39px auto;
  }


  .raffle-slider {
    width: 95%;
    margin: 0 auto;
    padding: 20px 0;
  }

  /* Ajuste de puntos de navegación */
  .slick-dots li button:before {
    font-size: 12px;
    color: #999;
    /* Color de los puntos inactivos */
  }

  /* Botones de navegación del slider */
  .slick-prev,
  .slick-next {
    z-index: 100;
    /* Asegura que los botones estén encima */
    width: 40px;
    height: 40px;
    background: rgba(0, 0, 0, 0.5);
    /* Fondo semitransparente */
    border-radius: 50%;
    /* Botones redondeados */
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }

  .slick-prev:hover,
  .slick-next:hover {
    background: #ff4500;
    /* Cambia el fondo al pasar el cursor */
  }

  .opc-tab {
    display: inline-block;
  }

  .div-numbers-month-winners {
    padding: 30px;
    flex-direction: column;
  }

  .container-puntos-fuertes {
    flex-direction: column;
    gap: 30px;
  }

  .container-form-log {
    width: 100%;
    padding: 120px 20px;
  }

  .left-field {
    width: 45%;
  }

  .modal-signup,
  .modal-noticaciones {
    width: 90%;
  }

  .pd-bottom-60-mobile {
    padding-bottom: 60px;
  }

  .img-poster-profile {
    height: auto;
    margin-top: 25px;
  }

  .img-poster-profile-slider {
    height: auto;
    margin-top: 25px;
  }

  .slidershadow {
    margin-top: 0px;
  }

  .banner-cta-rifa {
    padding: 300px 20px 20px;
    flex-direction: column;
    align-items: flex-start;

  }


  .banner-cta-rifa .gap-24 {
    grid-column-gap: 15px;
  }

  .element-wrap {
    flex-wrap: wrap;
  }

  .container-perfil-aliado {
    flex-direction: column;
    gap: 24px;
  }

  .grid-img-aliados,
  .grid-select-numbers,
  .grid-rifas,
  .grid-rifas-calientes,
  .grid-field-contact,
  .container-help,
  .grid-edit-profile {
    grid-template-columns: 1fr;
  }

  .grid-select-numbers .w-layout-vflex {
    order: 2;
  }

  .grid-select-numbers .img-w-border {
    order: 1;
  }

  .img-w-border {
    height: 400px;
  }

  .container-countdown {
    right: 10%;
    left: 10%;
  }

  .container-checkbox-terms {
    flex-wrap: wrap;
  }

  .checkbox-label.link {
    text-align: left;
    margin-left: 0;
  }

  .container-metodo-pago {
    padding: 20px;
  }

  .grid-btns-refeal {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 15px;
    grid-column-gap: 15px;
  }

  .grid-btns-refeal ._w-bg-yellow {
    font-size: 16px;
    padding: 10px;
  }


  .banner-home.mis-rifas {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .container-left-bar {
    padding: 120px 20px;
  }

  .container-left-bar .gap-16 {
    grid-column-gap: 0px;
  }

  .expandable-single.contact-page {
    padding: 20px;
  }

  .container.pd-bottom-96.pd-top-120.no-pd-bottom-mobile {
    padding-bottom: 0px;
  }

  #address-field, #image-profile {
    grid-area: span 1 / span 1 / span 1 / span 1;
  }

  .div-code-password {
    gap: 20px;
    max-width: 90%;
  }

  .copyright {
    text-align: center;
  }


  .container-proveedores {
    padding: 64px 20px;
  }


}


@media screen and (max-width: 479px) {
  .expandable-single {
    padding: 24px;
  }



}

.quantity-selector {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.btn-quantity {
  width: 180px;
  height: 40px;
  border: none;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease-in-out;
}

.btn-quantity:disabled {
  background-color: #ccc; /* Botón deshabilitado en gris */
  cursor: not-allowed;
}

.btn-quantity:not(:disabled) {
  background-color: #FFC72C; /* Amarillo para el botón activo */
}

.input-quantity {
  width: 180px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  border: 1px solid #ddd;
  border-radius: 8px;
  outline: none;
  margin-right: 15px;
  margin-left: 15px;
}

.link {
  display: inline-block;
  text-align: center;
  font-size: 14px;
  color: #FFC72C;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.link:hover {
  color: #A67C00;
  text-decoration: underline;
}

.link:active {
  color: #7A5C00;
}

.top {
  margin-top: 20px;
}

.bottom {
  margin-bottom: 20px;
}

.contenedor {
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 600px;
}
input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 7px;
  font-size: 16px;
}
button {
  background-color: #FFC107;
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 10px;
  color: white;
}
button:hover {
  background-color: #E0A800;
}

.alert-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.alert-box {
  background-color: white;
  padding: 5px;
  border-radius: 15px;
  color: #d67c0c;
  font-weight: bold;
  text-align: center;
  width: 100%;
  max-width: 600px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.alert-box-dos {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  color: #d67c0c;
  font-weight: bold;
  text-align: center;
  width: 100%;
  max-width: 600px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.alert-icon {
  width: 50px;
  height: 50px;
  border: 3px solid #d67c0c;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  color: #d67c0c;
  background-color: white;
  position: absolute;
  top: -60px;
}

.banner {
  background: linear-gradient(to right, #FFA500, #FF9800);
  color: white;
  font-weight: bold;
  font-size: 16px;
  padding: 20px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 700px;
  margin: 20px auto;
}

.banner-text {
  flex: 1;
  text-align: left;
}

.banner-button {
  background: white;
  color: #FF9800;
  font-weight: bold;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  transition: background 0.3s ease-in-out;
}

.banner-button:hover {
  background: #f5f5f5;
}